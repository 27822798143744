<template>
  <div>
    <v-carousel
      v-if="showCarousel"
      height="210"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-if="!contactAdded">
        <v-card
          flat
          height="200"
          class="ma-3 primarygrad d-flex"
          style="border-radius: 12px;"
        >
          <div class="trapezoid d-flex justify-center">
            <div>
              <h3
                class="d-md-flex d-none display-2 font-weight-bold primary--text mt-12"
              >
                Contacts
              </h3>
              <h3 class="d-md-none d-flex font-weight-bold primary--text mt-12">
                Contacts
              </h3>

              <h3 class="d-md-flex d-none font-weight-medium mt-2">
                Add Your First Contact
              </h3>
              <h5 class="d-md-none d-flex font-weight-medium mt-2">
                Add Your First Contact
              </h5>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="500"
            class="transparent pa-3 d-flex align-center justify-end"
          >
            <div>
              <h4
                class="white--text text-right font-weight-regular d-md-flex d-none"
              >
                Add your first contact to GoDial's CRM. GoDial is made of Lists
                and each lists consists of contacts. Lists are assigned to
                members for calling.
              </h4>
              <div class="d-flex justify-end">
                <v-btn to="/lists?contactAdd=true" depressed class="my-3">
                  ADD CONTACT
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-card>
      </v-carousel-item>
      <v-carousel-item v-if="!callPlaced">
        <v-card
          flat
          height="200"
          class="ma-3 primarygrad d-flex"
          style="border-radius: 12px;"
        >
          <div class="trapezoid d-flex justify-center">
            <div>
              <h3
                class="d-md-flex d-none display-2 font-weight-bold primary--text mt-12"
              >
                Place A Call
              </h3>
              <h3 class="d-md-none d-flex font-weight-bold primary--text mt-12">
                Place A Call
              </h3>
              <h3 class="d-md-flex d-none font-weight-medium mt-2">
                Call Your First Contact
              </h3>
              <h5 class="d-md-none d-flex font-weight-medium mt-2">
                Call Your First Contact
              </h5>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="500"
            class="transparent pa-3 d-flex align-center justify-end"
          >
            <div>
              <h4
                class="white--text text-right font-weight-regular d-md-flex d-none "
              >
                There is no better way to see the power of GoDial without
                placing a call. Download our App, Login and place a call.
              </h4>
              <h4
                class="white--text text-right my-3 font-weight-bold d-md-flex d-none"
              >
                Install Godial Mobile App > Login > Click on Start Dialing
              </h4>
              <div class="d-flex justify-end">
                <v-btn to="/apps" exact depressed class="my-3">
                  Show App Link
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-card>
      </v-carousel-item>
      <v-carousel-item v-if="trialPlan && !isEmailVerified">
        <v-card
          flat
          height="200"
          class="ma-3 primarygrad d-flex"
          style="border-radius: 12px;"
        >
          <div class="trapezoid d-flex justify-center">
            <div>
              <h3
                class="d-md-flex d-none display-2 font-weight-bold primary--text mt-12"
              >
                Verify Email
              </h3>
              <h3 class="d-md-none d-flex font-weight-bold primary--text mt-12">
                Verify Email
              </h3>
              <h3 class="d-md-flex d-none font-weight-medium mt-2">
                Your Email is not verified.
              </h3>
              <h5 class="d-md-none d-flex font-weight-medium mt-2">
                Your Email is not verified.
              </h5>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="500"
            class="transparent pa-3 d-flex align-center justify-end"
          >
            <div>
              <h4
                class="white--text text-right font-weight-regular d-md-flex d-none"
              >
                Verify your account to activate upto limit 100 calls during
                trial. Your account is restricted to 20 calls a day during
                trial.
              </h4>
              <div class="d-flex justify-end">
                <v-btn exact depressed class="my-3" @click="verifyEmail()"
                  >Verify Email</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-card>
      </v-carousel-item>

      <v-carousel-item v-if="!teamAdded">
        <v-card
          flat
          height="200"
          class="ma-3 primarygrad d-flex"
          style="border-radius: 12px;"
        >
          <div class="trapezoid d-flex justify-center">
            <div>
              <h3
                class="d-md-flex d-none display-2 font-weight-bold primary--text mt-12"
              >
                Add Team
              </h3>
              <h3 class="d-md-none d-flex font-weight-bold primary--text mt-12">
                Add Team
              </h3>
              <h3 class="d-md-flex d-none font-weight-medium mt-2">
                Invite Your Members
              </h3>
              <h5 class="d-md-none d-flex font-weight-medium mt-2">
                Invite Your Members
              </h5>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="500"
            class="transparent pa-3 d-flex align-center justify-end"
          >
            <div>
              <h4
                class="white--text text-right font-weight-regular d-md-flex d-none"
              >
                Being single is no fun! Let's start adding your team in Godial.
              </h4>
              <div class="d-flex justify-end">
                <v-btn to="/users?memberAdd=true" exact depressed class="my-3">
                  Invite Team
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-card>
      </v-carousel-item>
      <v-carousel-item v-if="detailsEmpty">
        <v-card
          flat
          height="200"
          class="ma-3 primarygrad d-flex "
          style="border-radius: 12px;"
        >
          <div class="trapezoid d-flex justify-center">
            <div>
              <h3
                class="d-md-flex d-none display-2 font-weight-bold primary--text mt-12"
              >
                Profile
              </h3>
              <h3 class="d-md-none d-flex font-weight-bold primary--text mt-12">
                Profile
              </h3>
              <h3 class="d-md-flex d-none font-weight-medium mt-2">
                Help us personalize your GoDial experience
              </h3>
              <h5 class="d-md-none d-flex font-weight-medium mt-2">
                Help us personalize your GoDial experience
              </h5>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="500"
            class="transparent pa-3 d-flex align-center justify-end"
          >
            <div>
              <h4
                class="white--text text-right font-weight-regular d-md-flex d-none"
              >
                Answer a few simple questions to help us optimize GoDial for you
              </h4>
              <div class="d-flex justify-end">
                <v-btn exact depressed class="my-3" @click="dialog = true"
                  >Fill Details</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-card>
        <v-card-title class="primarygrad py-4 white " style="color:white">
          Basic Information
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon color="white">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5 mb-0 pb-0 px-10">
          <v-select
            v-model="teamSize"
            outlined
            dense
            label="Your Team Size"
            prepend-inner-icon="mdi-account-group"
            :items="[
              { text: 'We just Started-  0 to 5 members', value: 5 },
              { text: 'We are growing- 5 to 20 members', value: 20 },
              {
                text: 'We are well established- 20 to 100 members',
                value: 100,
              },
              { text: 'We are an enterprise- 100+ members', value: 500 },
            ]"
          />
          <v-combobox
            v-model="industry"
            prepend-inner-icon="mdi-factory"
            label="Your Industry"
            :items="industryItems"
            item-text="name"
            item-value="name"
            :search-input.sync="search"
            hide-selected
            outlined
            dense
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <v-combobox
            v-model="currentTools"
            :items="[
              'Google Sheets',
              'Leadsquare',
              'Hubspace',
              'Facebook Ads',
              'Wordpress',
              'Zapier',
              'Integromat',
              'SugarCRM',
              'Runo',
              'Rolo',
              'Neodove',
              'GetCalley',
              'Shopify',
              'IndiaMart',
              'JustDial',
              'Pipefy',
              'PipeDrive',
              'Zoho CRM',
              'Freshworks',
              'G Suite',
              'Tally',
              'Khatabook',
              'Knowlarity',
              'Servetel',
              'Ozonetel',
              'Exotel',
              'Zoho Books',
              'Trello',
            ]"
            :search-input.sync="search2"
            label="Which Of These Tools You Use Everyday"
            prepend-inner-icon="mdi-cog-box"
            hide-selected
            multiple
            deletable-chips
            outlined
            dense
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search2 }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <v-combobox
            v-model="useOfGodial"
            :items="[
              'Tele Calling',
              'Customer Support',
              'CRM',
              'Lead Generation',
              'Inbound Calls',
              'Bulk Messaging',
              'Performance Tracking',
              'Attendance Tracking',
              'Work From Home',
              'Sales',
            ]"
            :search-input.sync="search3"
            label="What Would You Like To Use GoDial For"
            prepend-inner-icon="mdi-bullseye-arrow"
            hide-selected
            multiple
            deletable-chips
            outlined
            dense
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search3 }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>

          <v-combobox
            v-model="jobRole"
            :items="[
              'HR',
              'Director/Founder/CEO',
              'Product Manager',
              'Sales Manager',
              'Operations Manager',
              'CTO',
              'CFO',
              'COO',
              'Admin',
              'Sales Executive',
            ]"
            label="Your Job Role"
            :search-input.sync="search4"
            prepend-inner-icon="mdi-toolbox"
            hide-selected
            outlined
            dense
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search4 }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>

          <v-menu
            ref="menu"
            v-model="modalDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dob"
                label="Birthday date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dob"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="saveDt"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark class="primarygrad" @click="save">
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "dispoList",
      "contactEmpty",
      "logEmpty",
      "teamEmpty",
    ]),
    showCarousel() {
      console.log(
        "First time valeus for onboarding are ",
        this.contactAdded,
        this.callPlaced,
        this.teamAdded,
        this.detailsEmpty
      );
      return (
        !this.contactAdded ||
        !this.callPlaced ||
        !this.teamAdded ||
        this.detailsEmpty ||
        (this.trialPlan && !this.isEmailVerified)
      );
    },

    detailsEmpty() {
      try {
        var empty =
          typeof this.$store.getters.companyDetails.teamSize == "undefined" ||
          typeof this.$store.getters.companyDetails.industry == "undefined" ||
          typeof this.$store.getters.companyDetails.currentTools ==
            "undefined" ||
          typeof this.$store.getters.companyDetails.useOfGodial ==
            "undefined" ||
          typeof this.$store.getters.user.jobRole == "undefined" ||
          typeof this.$store.getters.user.dob == "undefined"
            ? true
            : false;
        return empty;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
  data() {
    return {
      activePicker: null,
      industryItems: [
        {
          name: "Accounting",
        },
        {
          name: "Airlines/Aviation",
        },
        {
          name: "Alternative Dispute Resolution",
        },
        {
          name: "Alternative Medicine",
        },
        {
          name: "Animation",
        },
        {
          name: "Apparel/Fashion",
        },
        {
          name: "Architecture/Planning",
        },
        {
          name: "Arts/Crafts",
        },
        {
          name: "Automotive",
        },
        {
          name: "Aviation/Aerospace",
        },
        {
          name: "Banking/Mortgage",
        },
        {
          name: "Biotechnology/Greentech",
        },
        {
          name: "Broadcast Media",
        },
        {
          name: "Building Materials",
        },
        {
          name: "Business Supplies/Equipment",
        },
        {
          name: "Capital Markets/Hedge Fund/Private Equity",
        },
        {
          name: "Chemicals",
        },
        {
          name: "Civic/Social Organization",
        },
        {
          name: "Civil Engineering",
        },
        {
          name: "Commercial Real Estate",
        },
        {
          name: "Computer Games",
        },
        {
          name: "Computer Hardware",
        },
        {
          name: "Computer Networking",
        },
        {
          name: "Computer Software/Engineering",
        },
        {
          name: "Computer/Network Security",
        },
        {
          name: "Construction",
        },
        {
          name: "Consumer Electronics",
        },
        {
          name: "Consumer Goods",
        },
        {
          name: "Consumer Services",
        },
        {
          name: "Cosmetics",
        },
        {
          name: "Dairy",
        },
        {
          name: "Defense/Space",
        },
        {
          name: "Design",
        },
        {
          name: "E-Learning",
        },
        {
          name: "Education (College)",
        },
        {
          name: "Education (Course)",
        },
        {
          name: "Education (School)",
        },

        {
          name: "Education",
        },
        {
          name: "Environmental Services",
        },
        {
          name: "Events Services",
        },
        {
          name: "Executive Office",
        },
        {
          name: "Facilities Services",
        },
        {
          name: "Farming",
        },
        {
          name: "Finance",
        },
        {
          name: "BPO",
        },
        {
          name: "Fine Art",
        },
        {
          name: "Fishery",
        },
        {
          name: "Food Production",
        },
        {
          name: "Food/Beverages",
        },
        {
          name: "Fundraising",
        },
        {
          name: "Furniture",
        },
        {
          name: "Gambling/Casinos",
        },
        {
          name: "Glass/Ceramics/Concrete",
        },
        {
          name: "Government Administration",
        },
        {
          name: "Government Relations",
        },
        {
          name: "Graphic Design/Web Design",
        },
        {
          name: "Health/Fitness",
        },
        {
          name: "Higher Education/Acadamia",
        },
        {
          name: "Hospital/Health Care",
        },
        {
          name: "Hospitality",
        },
        {
          name: "Human Resources/HR",
        },
        {
          name: "Import/Export",
        },
        {
          name: "Individual/Family Services",
        },
        {
          name: "Industrial Automation",
        },
        {
          name: "Information Services",
        },
        {
          name: "Information Technology/IT",
        },
        {
          name: "Insurance",
        },
        {
          name: "International Affairs",
        },
        {
          name: "International Trade/Development",
        },
        {
          name: "Internet",
        },
        {
          name: "Investment Banking/Venture",
        },
        {
          name: "Investment Management/Hedge Fund/Private Equity",
        },
        {
          name: "Judiciary",
        },
        {
          name: "Law Enforcement",
        },
        {
          name: "Law Practice/Law Firms",
        },
        {
          name: "Legal Services",
        },
        {
          name: "Legislative Office",
        },
        {
          name: "Leisure/Travel",
        },
        {
          name: "Library",
        },
        {
          name: "Loan",
        },
        {
          name: "Logistics/Procurement",
        },
        {
          name: "Luxury Goods/Jewelry",
        },
        {
          name: "Machinery",
        },
        {
          name: "Management Consulting",
        },
        {
          name: "Maritime",
        },
        {
          name: "Market Research",
        },
        {
          name: "Marketing/Advertising/Sales",
        },
        {
          name: "Mechanical or Industrial Engineering",
        },
        {
          name: "Media Production",
        },
        {
          name: "Medical Equipment",
        },
        {
          name: "Medical Practice",
        },
        {
          name: "Mental Health Care",
        },
        {
          name: "Military Industry",
        },
        {
          name: "Mining/Metals",
        },
        {
          name: "Motion Pictures/Film",
        },
        {
          name: "Museums/Institutions",
        },
        {
          name: "Music",
        },
        {
          name: "Nanotechnology",
        },
        {
          name: "Newspapers/Journalism",
        },
        {
          name: "Non-Profit/Volunteering",
        },
        {
          name: "Oil/Energy/Solar/Greentech",
        },
        {
          name: "Online Publishing",
        },
        {
          name: "Other Industry",
        },
        {
          name: "Outsourcing/Offshoring",
        },
        {
          name: "Package/Freight Delivery",
        },
        {
          name: "Packaging/Containers",
        },
        {
          name: "Paper/Forest Products",
        },
        {
          name: "Performing Arts",
        },
        {
          name: "Pharmaceuticals",
        },
        {
          name: "Philanthropy",
        },
        {
          name: "Photography",
        },
        {
          name: "Plastics",
        },
        {
          name: "Political Organization",
        },
        {
          name: "Primary/Secondary Education",
        },
        {
          name: "Printing",
        },
        {
          name: "Professional Training",
        },
        {
          name: "Program Development",
        },
        {
          name: "Public Relations/PR",
        },
        {
          name: "Public Safety",
        },
        {
          name: "Publishing Industry",
        },
        {
          name: "Railroad Manufacture",
        },
        {
          name: "Ranching",
        },
        {
          name: "Real Estate/Mortgage",
        },
        {
          name: "Recreational Facilities/Services",
        },
        {
          name: "Religious Institutions",
        },
        {
          name: "Renewables/Environment",
        },
        {
          name: "Research Industry",
        },
        {
          name: "Restaurants",
        },
        {
          name: "Retail Industry",
        },
        {
          name: "Security/Investigations",
        },
        {
          name: "Semiconductors",
        },
        {
          name: "Shipbuilding",
        },
        {
          name: "Sporting Goods",
        },
        {
          name: "Sports",
        },
        {
          name: "Staffing/Recruiting",
        },
        {
          name: "Supermarkets",
        },
        {
          name: "Telecommunications",
        },
        {
          name: "Textiles",
        },
        {
          name: "Think Tanks",
        },
        {
          name: "Tobacco",
        },
        {
          name: "Translation/Localization",
        },
        {
          name: "Transportation",
        },
        {
          name: "Utilities",
        },
        {
          name: "Venture Capital/VC",
        },
        {
          name: "Veterinary",
        },
        {
          name: "Warehousing",
        },
        {
          name: "Wholesale",
        },
        {
          name: "Wine/Spirits",
        },
        {
          name: "Wireless",
        },
        {
          name: "Writing/Editing",
        },
      ],
      search: "",
      trialPlan: false,
      managerAccount: [],
      search2: "",
      search3: "",
      search4: "",
      teamSize: "",
      industry: "",
      currentTools: "",
      useOfGodial: "",
      jobRole: "",
      modalDate: false,
      dob: null,
      dialog: false,
      contactAdded: true,
      teamAdded: true,
      callPlaced: true,
      isEmailVerified: false,
    };
  },
  watch: {
    modalDate(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  created() {
    this.checkContactAdded();
    this.checkLogAdded();
    this.checkTeamAdded();
    this.checkTrialPlan();
  },
  mounted() {},
  methods: {
    saveDt(date) {
      this.$refs.menu.save(date);
    },
    async checkTrialPlan() {
      this.trialPlan =
        this.$store.getters.companyDetails.plan.name === "Trial" ? true : false;
      var response = await this.$http.get(
        `${this.$store.state.ENDPOINT}/accounts`
      );

      this.managerAccount = response.body.filter((account) => {
        return account.role === "Manager";
      });
      this.isEmailVerified = this.managerAccount[0].isEmailVerified
        ? true
        : false;
    },
    checkContactAdded() {
      const self = this;
      this.$http
        .get(
          this.ENDPOINT +
            `/eventMappings/count?where[name]=CONTACT_ADDED&where[companyId]=${this.$store.getters.companyDetails.id}`
        )
        .then((response) => {
          response = response.body.count;
          self.contactAdded = response == 0 ? false : true;
        })
        .catch((err) => {
          console.log("Error in getting count: ", err);
        });
    },
    checkLogAdded() {
      const self = this;

      this.$http
        .get(
          this.ENDPOINT +
            `/eventMappings/count?where[name]=CALL_PLACED&where[companyId]=${this.$store.getters.companyDetails.id}`
        )
        .then((response) => {
          response = response.body.count;
          self.callPlaced = response == 0 ? false : true;
        })
        .catch((err) => {
          console.log("Error in getting count: ", err);
        });
    },
    checkTeamAdded() {
      const self = this;
      this.$http
        .get(
          this.ENDPOINT +
            `/eventMappings/count?where[name]=USER_ADDED&where[companyId]=${this.$store.getters.companyDetails.id}`
        )
        .then((response) => {
          response = response.body.count;
          self.teamAdded = response == 0 ? false : true;
        })
        .catch((err) => {
          console.log("Error in getting count: ", err);
        });
    },
    verifyEmail() {
      console.log("send email to maanger's account", this.managerAccount);

      if (!this.managerAccount[0].email) {
        return this.$swal({ type: "warning", text: "Please add email" });
      }

      this.$http
        .post(`${this.ENDPOINT}/accounts/send-verification-email`, {
          email: this.managerAccount[0].email,
        })
        .then((response) => {
          this.$swal({
            type: "success",
            text: `Email sent to ${this.managerAccount[0].email}.Please check your mail to verify your email.`,
          });
        })
        .catch((e) => {
          this.$swal({ type: "error", text: e.body.error.message });
        });
    },
    save() {
      const self = this;
      var payload = {};
      var userPayload = {};
      if (
        self.teamSize == "" ||
        self.industry == "" ||
        self.currentTools == "" ||
        self.useOfGodial == "" ||
        self.jobRole == "" ||
        self.dob == ""
      ) {
        return self.$swal({
          type: "warning",
          text: "Please fill the necessary details.",
        });
      }
      payload.teamSize = self.teamSize;
      payload.industry = self.industry;
      payload.currentTools = self.currentTools;
      payload.useOfGodial = self.useOfGodial;

      userPayload.jobRole = self.jobRole;
      userPayload.dob = self.dob;

      self.$http
        .patch(
          `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          payload
        )
        .then((response) => {
          self.$http
            .patch(
              `${self.ENDPOINT}/accounts/${self.$store.getters.user.id}`,
              userPayload
            )
            .then((response) => {
              self.$swal({ type: "success", text: "Successful" });
              self.dialog = false;

              this.$store.dispatch("refreshUser");
              this.$store.dispatch("getCompanyDetails");
            })
            .catch((err) => {
              self.$swal({
                type: "error",
                text: "Something went wrong. Couldn't Update User Details",
              });
            });
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Couldn't Update Company details",
          });
        });
    },
  },
};
</script>

<style>
.trapezoid {
  height: 0;
  width: 360px;
  border-bottom: 200px solid #ffffff8f;
  border-left: 15px solid #ffffff8f;
  border-right: 80px solid transparent;
}
</style>
