<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-stepper v-model="e1" v-if="!showWhatsapp">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1"> Welcome </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="contactAdded" step="2">
            Add A Contact
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="e1 > 3">
            First Call</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="4"> Finish</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container height="200px">
              <v-layout row wrap style="margin: 0px">
                <v-flex xs11 sm7 md7>
                  <v-row>
                    <h1 class="h1">Hey {{ getFirstName() }}!</h1>
                  </v-row>
                  <v-row style="text-align: justify">
                    <p class="p">
                      Welcome to GoDial. It’s a pleasure to meet you. My name is
                      Avijit, and I am the founder of GoDial. Let me personally
                      take to through a few steps to get you started! Click
                      START once you are ready.
                    </p>
                  </v-row>
                </v-flex>
                <v-flex xs1 sm1 md1></v-flex>
                <v-flex
                  xs12
                  sm4
                  md4
                  style="justify-content: center; text-align: center"
                >
                  <v-avatar size="170">
                    <img src="../assets/avijit.jpg" alt="Avijit Sarkar" />
                  </v-avatar>
                </v-flex>
              </v-layout>
            </v-container>

            <v-row style="margin: 0px">
              <v-spacer></v-spacer>

              <v-flex xs12 sm4 md4 style="text-align: center">
                <v-btn text small @click="fts('cancel')"> no thanks </v-btn>
                <v-btn color="primary" @click="e1 = 2"> Start </v-btn>
              </v-flex>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container height="200px" fluid>
              <v-layout row wrap style="margin: 0px">
                <v-flex xs11 sm7 md7>
                  <v-row>
                    <h1 class="h1">Your First Contact</h1>
                  </v-row>
                  <v-row style="text-align: justify">
                    <p class="p">
                      GoDial's CRM is made up of Lists and each list is a set of
                      contacts. Lists are assigned to one or many Members. For
                      now, Let's create a dummy contact for testing. We will add
                      this contact to your Default list and assign it to you as
                      well.
                    </p>
                  </v-row>
                </v-flex>
                <v-flex xs1 sm1 md1></v-flex>
                <v-flex xs12 sm4 md4>
                  <v-sheet
                    color="white"
                    elevation="7"
                    outlined
                    rounded
                    shaped
                    class="mx-auto"
                  >
                    <v-form v-model="valid" outline>
                      <v-container text-xs-center>
                        <v-text-field
                          v-model="name"
                          :rules="nameRules"
                          label="Name"
                          required
                          placeholder="John Doe"
                        ></v-text-field>

                        <v-text-field
                          v-model="phone"
                          :rules="phoneRules"
                          label="Phone"
                          required
                          placeholder="+91 7044406666"
                        ></v-text-field>

                        <v-btn
                          color="primary"
                          block
                          :disabled="contactAdded"
                          @click="addContact()"
                        >
                          Add</v-btn
                        >
                      </v-container>
                    </v-form>
                  </v-sheet>
                </v-flex>
              </v-layout>
            </v-container>
            <v-row style="margin: 0px">
              <v-col cols="4">
                <v-btn text raised block @click="e1 = 1"> BACK </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  text
                  raised
                  block
                  @click="clickSkip"
                  style="color: #a24554;"
                >
                  SKIP
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  block
                  @click="
                    if (contactAdded) {
                      e1 = 3;
                    } else {
                      snackbar = true;
                      snackbarText = 'Please add a contact';
                      color = '#c62828';
                    }
                  "
                >
                  NEXT
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container height="200px" fluid>
              <v-layout row wrap style="margin: 0px">
                <v-flex xs12 sm7 md7>
                  <v-row>
                    <h1 class="h1">Let's Make A Call</h1>
                  </v-row>
                  <v-row style="text-align: justify">
                    <p class="p">
                      There is no better way to see the power of GoDial without
                      placing a call. Scan the QR on your phone and begin.
                    </p>

                    <ul style="list-style-type: circle">
                      <li>Install the App and Login</li>
                      <li>Your contact will appear in the dialer</li>
                      <li>Click Start</li>
                      <li>
                        After the call, set a disposition, add notes and more
                        then click Next
                      </li>
                    </ul>
                  </v-row>
                </v-flex>
                <v-flex xs12 sm1 md1></v-flex>
                <v-flex
                  class="mt-3"
                  xs12
                  sm4
                  md4
                  style="text-align: center; justify-content: center"
                >
                  <v-sheet outlined rounded shaped class="mx-auto">
                    <img
                      src="../assets/appdownloadqr.png"
                      height="200"
                      style="margin-left: -5px"
                    />
                  </v-sheet>

                  <v-btn
                    color="primary"
                    style="margin-top: 15px"
                    @click="openLink"
                  >
                    Open Link
                  </v-btn>
                </v-flex>
              </v-layout>

              <div style="margin-top: 20px; margin-bottom: 10px">
                <v-row v-if="!contactCalled" style="margin: 0px">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="green"
                      size="25"
                      style="margin-right: 10px"
                    ></v-progress-circular>
                    Waiting for you to make the first call
                    <v-skeleton-loader type="article"></v-skeleton-loader>
                  </v-col>
                </v-row>

                <v-row v-else style="margin: 0px">
                  <v-flex xs4 sm4 md4 style="text-align : center">
                    <v-avatar
                      color="primary"
                      size="60"
                      style="margin-top: 24px"
                    >
                      <v-icon size="30" color="white">mdi-check</v-icon>
                    </v-avatar>
                  </v-flex>

                  <v-flex xs8 sm8 md8>
                    <v-card elevation="3">
                      <v-card-text>
                        <v-flex style="margin-left: 20px">
                          <v-row>
                            <v-icon color="primary">mdi-account-circle</v-icon>
                            <div style="margin-left: 7px">
                              {{ contactCalledDetails.name }}
                            </div>
                          </v-row>
                          <v-row style="margin-top: 5px">
                            <v-icon color="primary">mdi-phone-outline</v-icon>
                            <div style="margin-left: 7px">
                              {{ contactCalledDetails.phone }}
                            </div>
                          </v-row>
                          <v-row>
                            <v-icon color="primary"
                              >mdi-map-marker-question</v-icon
                            >
                            <v-chip
                              class="text--white ma-2"
                              style="color: #f1f1f1 !important"
                              :color="getColor(contactCalledDetails.dispo)"
                            >
                              {{ contactCalledDetails.dispo }}
                            </v-chip>
                          </v-row>
                        </v-flex>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </div>
            </v-container>

            <v-row style="margin: 0px">
              <v-col cols="6">
                <v-btn block text @click="e1 = 2"> Back </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="e1 = 4"> Continue </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container height="200px" fluid>
              <v-layout row wrap style="margin: 0px">
                <v-flex
                  xs12
                  sm4
                  md4
                  style="text-align: center; justify-content: center"
                >
                  <v-avatar color="primary" size="100" style="margin-top: 20px">
                    <v-icon size="40" color="white">mdi-thumb-up</v-icon>
                  </v-avatar>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-row>
                    <h1 class="h1">Great!</h1>
                  </v-row>
                  <v-row>
                    <p class="p">
                      You are a pro now! You have already added a contact and
                      placed a call. Here are some things to do next
                    </p>

                    <v-list dense>
                      <v-list-item
                        @click="
                          fts();
                          $router.replace({
                            path: '/users',
                            query: { tp: true },
                          });
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>contacts</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Add your team</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        @click="
                          fts();
                          $router.replace({
                            path: '/lists',
                            query: { cp: true },
                          });
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>account_circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Import your contacts</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        @click="
                          fts();
                          $router.replace('/whatsapp-settings');
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Connect Whatsapp</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        @click="
                          fts();
                          $router.replace('/reports');
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>bar_chart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Checkout your reports</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-container>
            <v-row style="margin: 0px">
              <v-col cols="6">
                <v-btn block text @click="e1 = 3"> Back </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" @click="fts"> Finish </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

    <v-snackbar v-model="snackbar" multi-line :color="color">
      <h3 style="font-weight: 500; font-size: 13px; text-transform: uppercase">
        {{ snackbarText }}
      </h3>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
const _ = require("lodash");
import validator from "../js/validator.js";
import fb from "../js/firebase";

export default {
  computed: {
    ...mapGetters(["ENDPOINT", "dispoList"]),
  },
  data() {
    return {
      skipClicked: false,
      showWhatsapp: false,
      snackbar: false,
      snackbarText: "",
      color: "success",
      e1: 1,
      intervalWatch: null,
      dialog: false,
      name: "",
      phone: "",
      defaultList: "",
      contactAdded: false,
      contactAddedDetails: {},
      contactCalled: false,
      contactCalledDetails: {},
      valid: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 15 || "Name must be less than 15 characters",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length >= 5 || "Phone must be more than 5 characters",
      ],
    };
  },
  created() {
    //get the default list
    this.$http
      .get(
        `${this.ENDPOINT}/lists?filter[where][companyId]=${this.$store.state.user.companyId}`
      )
      .then((response) => {
        this.defaultList = response.body[0].id;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    clickSkip() {
      this.contactAdded = true;
      this.e1 = 4;
      this.skipClicked = true;
    },
    openLink() {
      try {
        window.open("http://onelink.to/godialent", "_blank");
      } catch (e) {
        console.log(e);
      }
    },
    checkForDial() {
      this.intervalWatch = setInterval(() => {
        var filter = { fields: ["id", "ftsContactDone"] };
        this.$http
          .get(
            `${this.ENDPOINT}/companies/${
              this.$store.getters.user.companyId
            }?filter=${encodeURIComponent(JSON.stringify(filter))}`
          )
          .then(async (response) => {
            response = response.body;
            if (response.ftsContactDone) {
              let fields = { fields: ["id", "name", "phone", "dispo"] };
              let contact = await this.$http.get(
                `${this.ENDPOINT}/contacts/${
                  this.contactAddedDetails.id
                }?filter=${encodeURIComponent(JSON.stringify(fields))}`
              );
              contact = contact.body;
              this.contactCalled = true;
              this.contactCalledDetails = contact;
              clearInterval(this.intervalWatch);
            }
          })
          .catch((e) => {
            console.log("** Error in company first contact watcher **", e);
          });
      }, 10000);
    },
    getColor(dispo) {
      const self = this;
      if (dispo == "NEW") return "green";
      var d = _.find(self.dispoList, (d) => {
        return d.title == dispo;
      });
      if (d == undefined) {
        return "#E5E5E5";
      }
      return d.hex;
    },

    open() {
      this.dialog = true;

      console.log(
        "this.$store.state.companyDetails",
        this.$store.state.companyDetails
      );

      if (this.$store.state.companyDetails.sourceFrom == "whatsapp-crm") {
        this.showWhatsapp = true;
      } else {
        this.showWhatsapp = false;
      }
    },
    getFirstName() {
      return this.$store.state.user.name
        .split(" ")
        .slice(0, -1)
        .join(" ");
    },
    fts(what) {
      const self = this;
      self.$http
        .patch(
          `${self.$store.state.ENDPOINT}/companies/${self.$store.state.companyDetails.id}`,
          {
            ftsDone: true,
            modifiedOn: new Date(),
          }
        )
        .then((response) => {
          response = response.body;
          self.dialog = false;
          if (what == "cancel") {
            fb.log("fts_skip");
          } else {
            clearInterval(self.intervalWatch);
            fb.log("fts_finish");
          }
          // hit store to refresh
          self.$store.dispatch("getCompanyDetails", { companyId: response.id });
          this.$emit("refreshHome");
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    addContact() {
      var patchObj = {
        name: this.name,
        phone: validator.phoneNumberValidator(this.phone),
        secondPhone: "",
        email: "",
        companyName: "",
        note: "",
        remarks: "",
        extra: "",
        companyId: this.$store.state.user.companyId,
        listId: this.defaultList,
        accountsId: "",
        smsSent: false,
        emailSent: false,
        linkSent: false,
        appSent: false,
        customFields: [],
        ftsContact: true,
      };

      this.$http
        .post(`${this.ENDPOINT}/contacts`, patchObj)
        .then((response) => {
          this.contactAddedDetails = response.body;
          //this.$swal({ type: "success", text: "Successful" });
          this.contactAdded = true;
          this.checkForDial();
          this.e1 = 3;
        })
        .catch((err) => {
          if (err.body.error && err.body.error.message) {
            if (err.body.error.message.includes("is not unique")) {
              this.$swal({
                type: "error",
                text: "This phone number already exists in this list",
              });
            } else if (err.body.error.message.includes("is not valid")) {
              this.$swal({
                type: "error",
                text: "Enter a phone number",
              });
            } else {
              this.$swal({ type: "error", text: err.body.error.message });
            }

            return;
          }

          this.$swal({
            type: "error",
            text: "Error while adding this contact",
          });
        });
    },
  },
};
</script>
<style scoped>
.p {
  font-size: 13.5px;
}

.h1 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

li {
  font-size: 14px;
}
</style>
