<template>
  <v-container text-xs-center fluid>
    <!-- Dashbord Heading -->
    <Onboard v-if="$store.state.companyDetails" />
    <v-card flat class="transparent px-3 d-flex" style="padding-top:20px;">
      <div class="live2"></div>
      <h3 class="font-weight-regular secondary--text">
        {{ currentTime }}
      </h3>
    </v-card>

    <!-- Graph & HomeCards -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="5" class="pa-0 ma-0">
        <homeCard
          :compiled-data="{
            totalCallsToday: totalCallsToday,
          }"
        >
        </homeCard>
      </v-col>

      <v-col cols="12" md="7" class="pa-0 ma-0">
        <v-card color="pa-0 transparent" flat class="pa-3 fill-height">
          <v-card v-if="totalCallsToday > 0" color="transparent" flat>
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  color="secondarygrad"
                  class="rounded fill-height d-flex align-center pa-3"
                >
                  <bar-chart
                    class="text-align-center justify-content-center mx-auto"
                    style="max-width: 300px;padding-top: 14px !important;"
                    :chart-data="datacollection"
                    :options="{
                      responsive: true,
                      legend: { position: 'bottom' },
                      scales: {
                        yAxes: [
                          {
                            gridLines: { display: false },
                            ticks: { beginAtZero: true },
                          },
                        ],
                        xAxes: [
                          {
                            type: 'time',
                            gridLines: { display: false },
                            time: { unit: 'month' },
                          },
                        ],
                      },
                    }"
                  ></bar-chart>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  color="secondarygrad"
                  class="rounded fill-height d-flex align-center pa-3"
                >
                  <div
                    v-if="!loadChart"
                    style="text-align: center; padding: 50px"
                  >
                    <v-progress-circular
                      :width="3"
                      color="green"
                      indeterminate
                    />
                  </div>
                  <div v-else align="center" class="mx-auto">
                    <doughnut-chart
                      v-if="loadChart"
                      :chart-data="datacollectionDoughnut"
                      class="text-align-center justify-content-center"
                      style="max-width: 300px;padding-top: 14px !important;"
                      :options="{
                        responsive: true,
                        legend: { position: 'bottom', display: labelShown },
                      }"
                    ></doughnut-chart>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            v-else
            class="secondarygrad rounded fill-height  d-flex align-center"
          >
            <v-card
              flat
              color="transparent"
              width="100%"
              class="customPaddingVCard"
            >
              <v-img contain height="100" :src="noCall" />
              <v-card-text class="text-center secondary--text">
                <h2 style="padding-top:10px;">No Calls Today</h2>
              </v-card-text>
            </v-card>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <!-- Member who are active -->
    <div>
      <!-- Loader -->
      <div v-if="preloader">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="6" md="3">
            <v-skeleton-loader type="card-avatar"> </v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-skeleton-loader type="card-avatar"> </v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-skeleton-loader type="card-avatar"> </v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-skeleton-loader type="card-avatar"> </v-skeleton-loader>
          </v-col>
        </v-row>

        <!-- <v-card flat class=" d-flex justify-center">
            <v-progress-circular indeterminate color="primary" :size="38"/>
        </v-card> -->
      </div>
      <!-- Member Cards -->
      <div>
        <v-row class="pa-0 ma-0">
          <v-col cols="7" md="9">
            <v-text-field
              v-model="search"
              prepend-inner-icon="search"
              solo-inverted
              flat
              placeholder="Search Member Activity"
              dense
              single-line
              :hide-details="true"
              @keyup.enter="getLatestActivity(4, 0, search)"
              @click:clear="clearSearch"
              class="customBorderRadius"
            />
          </v-col>
          <v-col cols="5" md="3">
            <v-btn
              color="primarygrad white--text"
              block
              class="customBorderRadius"
              @click="getLatestActivity(4, 0, search)"
            >
              <v-icon left>search</v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>

        <!-- <v-row v-if="visibleUsers.length > 0 && !preloader" class="pa-0 ma-0">
          <v-col
            v-for="(user, index) in visibleUsers"
            :key="index"
            class="d-flex child-flex"
            cols="12"
            sm="6"
            md="3"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="secondarygrad pa-2 fill-height rounded customPaddingVCard"
                :elevation="hover ? 6 : 2"
              >
                <router-link
                  :to="{ path: `/performance?userid=${user._id}` }"
                  style="text-decoration:none"
                >
                  <div class="d-flex justify-center">
                    <v-avatar
                      :color="user.activeAgent ? 'primarygrad' : 'grey'"
                      size="62"
                      class="elevation-2"
                      :style="
                        user.picture && user.activeAgent
                          ? 'border: 4px solid #8BC34A'
                          : null
                      "
                    >
                      <v-img
                        v-if="user.picture"
                        :src="getImage(user.picture)"
                      />
                      <h2 v-else class="font-weight-medium white--text">
                        {{ user.name.substring(0, 2).toUpperCase() }}
                      </h2>
                    </v-avatar>
                  </div>
                  <div class="pt-2">
                    <h3 class="text-center text-uppercase secondary--text">
                      {{ user.name }}
                    </h3>
                    <p
                      class="text-center text-md-body-1 secondary--text text-lighten-1"
                    >
                      ({{ user.role }})
                    </p>
                  </div>
                  <div
                    class="d-flex justify-center text-sm-body-2 secondary--text"
                  >
                    <v-icon size="22" color="primary lighten-1" class="pr-1"
                      >phone</v-icon
                    >
                    {{
                      user.last != undefined
                        ? user.last.callCounts > 0
                          ? user.last.callCounts
                          : 0
                        : 0
                    }}
                    <v-icon
                      size="22"
                      color="primary lighten-1"
                      class="pl-3 pr-1"
                      >av_timer</v-icon
                    >
                    {{
                      user.last != undefined
                        ? user.last.callCounts > 0
                          ? user.last.totalDuration
                          : "0 min"
                        : "0 mins"
                    }}
                  </div>
                  <div
                    class="d-flex justify-center font-weight-light mt-2 text-caption secondary--text"
                  >
                    <p class="pr-2">Last Call:</p>
                    <p v-if="user.last != undefined">
                      {{
                        $moment(user.last.calledOn, "YYYY-MM-DD h:mm A").format(
                          "Do MMMM YYYY, h:mm A"
                        )
                      }}
                    </p>
                    <p v-else>No Call History</p>
                  </div>
                </router-link>
              </v-card>
            </v-hover>
          </v-col>
        </v-row> -->

        <div style="padding: 12px">
          <v-simple-table
            v-if="visibleUsers.length > 0 && !preloader"
            style="border-radius: 0px!important;"
          >
            <template v-slot:default>
              <tbody>
                <tr v-for="(user, index) in visibleUsers" :key="index">
                  <td style="padding-top: 8px;padding-bottom:8px;">
                    <v-hover v-slot="{ hover }">
                      <v-flex
                        class="secondarygrad pa-2 fill-height rounded customPaddingVCard"
                        :elevation="hover ? 6 : 2"
                      >
                        <router-link
                          :to="{ path: `/performance?userid=${user._id}` }"
                          style="text-decoration:none"
                        >
                          <v-row align="center">
                            <v-col cols="9" sm="5" class="d-flex align-center">
                              <v-avatar
                                :color="
                                  user.activeAgent ? 'primarygrad' : 'grey'
                                "
                                size="62"
                                class="elevation-2 customColorForAvatarText"
                                :style="
                                  user.picture && user.activeAgent
                                    ? 'border: 4px solid #8BC34A'
                                    : null
                                "
                              >
                                <v-img
                                  v-if="user.picture"
                                  :src="getImage(user.picture)"
                                  class="align-self-center"
                                />
                                <template v-else>{{
                                  user.name.substring(0, 2).toUpperCase()
                                }}</template>
                              </v-avatar>
                              <div class="ml-2" style="padding-top: 10px;">
                                <h3 class="text-uppercase secondary--text">
                                  {{ user.name }}
                                </h3>
                                <p
                                  class="text-md-body-1 secondary--text text-lighten-1"
                                >
                                  ({{ user.role }})
                                </p>
                              </div>
                            </v-col>

                            <v-col cols="3" sm="2">
                              <v-icon size="22" color="primary lighten-1"
                                >phone</v-icon
                              >
                              {{
                                user.last != undefined
                                  ? user.last.callCounts > 0
                                    ? user.last.callCounts
                                    : 0
                                  : 0
                              }}
                            </v-col>
                            <v-col cols="4" sm="2">
                              <v-icon size="22" color="primary lighten-1"
                                >av_timer</v-icon
                              >
                              {{
                                user.last != undefined
                                  ? user.last.callCounts > 0
                                    ? user.last.totalDuration
                                    : "0 min"
                                  : "0 mins"
                              }}
                            </v-col>
                            <v-col cols="8" sm="3">
                              Last Call:
                              <template v-if="user.last != undefined">
                                {{
                                  $moment(
                                    user.last.calledOn,
                                    "YYYY-MM-DD h:mm A"
                                  ).format("Do MMMM YYYY, h:mm A")
                                }}
                              </template>
                              <template v-else>No Call History</template>
                            </v-col>
                          </v-row>
                        </router-link>
                      </v-flex>
                    </v-hover>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <!-- <v-simple-table v-if="visibleUsers.length > 0 && !preloader">
          <template v-slot:default>
            <tbody>
              <tr v-for="(user, index) in visibleUsers" :key="index">
                <td>
                  <v-hover v-slot="{ hover }">
                    <v-card
                      class="secondarygrad pa-2 fill-height rounded customPaddingVCard"
                      :elevation="hover ? 6 : 2"
                    >
                      <router-link
                        :to="{ path: `/performance?userid=${user._id}` }"
                        style="text-decoration:none"
                      >
                        <v-avatar
                          :color="user.activeAgent ? 'primarygrad' : 'grey'"
                          size="62"
                          class="elevation-2"
                          :style="
                            user.picture && user.activeAgent
                              ? 'border: 4px solid #8BC34A'
                              : null
                          "
                        >
                          <v-img
                            v-if="user.picture"
                            :src="getImage(user.picture)"
                          />
                          <template v-else>{{
                            user.name.substring(0, 2).toUpperCase()
                          }}</template>
                        </v-avatar>
                        <template>
                          <v-row align="center">
                            <v-col>
                              <h3
                                class="text-center text-uppercase secondary--text"
                              >
                                {{ user.name }}
                              </h3>
                              <p
                                class="text-center text-md-body-1 secondary--text text-lighten-1"
                              >
                                ({{ user.role }})
                              </p>
                            </v-col>
                            <v-col>
                              <v-icon size="22" color="primary lighten-1"
                                >phone</v-icon
                              >
                              {{
                                user.last != undefined
                                  ? user.last.callCounts > 0
                                    ? user.last.callCounts
                                    : 0
                                  : 0
                              }}
                            </v-col>
                            <v-col>
                              <v-icon size="22" color="primary lighten-1"
                                >av_timer</v-icon
                              >
                              {{
                                user.last != undefined
                                  ? user.last.callCounts > 0
                                    ? user.last.totalDuration
                                    : "0 min"
                                  : "0 mins"
                              }}
                            </v-col>
                            <v-col>
                              Last Call:
                              <template v-if="user.last != undefined">
                                {{
                                  $moment(
                                    user.last.calledOn,
                                    "YYYY-MM-DD h:mm A"
                                  ).format("Do MMMM YYYY, h:mm A")
                                }}
                              </template>
                              <template v-else>No Call History</template>
                            </v-col>
                          </v-row>
                        </template>
                      </router-link>
                    </v-card>
                  </v-hover>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
        <!-- Pagination -->
        <div>
          <v-flex v-if="visibleUsers.length > 0 && !preloader" xs12 sm12 md12>
            <div class="v-data-footer">
              <div class="v-data-footer__select">
                Rows per page:
                <div
                  class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
                >
                  <v-select
                    v-model="records"
                    :items="recordsPerPage"
                    style="margin: 5px"
                    @change="pagination"
                  ></v-select>
                </div>
              </div>
              <div class="v-data-footer__icons-before">
                <v-btn
                  :disabled="page == 1 ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="prevSet"
                >
                  <v-icon :size="24">arrow_left</v-icon>
                </v-btn>
              </div>
              <div class="v-data-footer__pagination">
                {{ `${page}/${pageCount} of ${totalRecords}` }}
              </div>
              <div class="v-data-footer__icons-after">
                <v-btn
                  :disabled="page >= pageCount ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="nextSet"
                >
                  <v-icon :size="24">arrow_right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-flex>
        </div>
      </div>
      <!-- No agent img -->
      <div v-if="visibleUsers.length <= 0 && !preloader">
        <v-card
          class="mx-3 mt-3 pb-3 secondarygrad fill-height d-flex justify-center"
        >
          <div>
            <v-img :src="noAgent" width="200" />
            <h3>No Members available.</h3>
          </div>
        </v-card>
      </div>
    </div>

    <!-- not affecting -->

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primarygrad" dark>
        <v-card-text>
          Please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contactDialog" width="800">
      <v-card color="primary" dark>
        <v-carousel
          :show-arrows="false"
          :horizontal="true"
          hide-delimiter-background
        >
          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/clickList.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>

          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/addContact.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>

          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/AssignMembersImg.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <v-dialog v-model="callDialog" width="800">
      <v-card color="primary" dark>
        <v-carousel
          :show-arrows="false"
          :horizontal="true"
          hide-delimiter-background
        >
          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/callNumber.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>

          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/setDispo.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <v-dialog v-model="memberDialog" width="800">
      <v-card color="primary" dark>
        <v-carousel
          :show-arrows="false"
          :horizontal="true"
          hide-delimiter-background
        >
          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/clickMembers.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>

          <v-carousel-item>
            <v-card
              v-touch="{
                left: () => swipe('Left'),
                right: () => swipe('Right'),
              }"
              light
            >
              <v-card-text>
                <v-flex xs12 sm12 md12>
                  <v-img src="../assets/addMembers.png"></v-img>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <welcome-popup
      ref="welcomePopupOpen"
      @refreshHome="refresh"
    ></welcome-popup>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
// import PieChart from "../js/pie.js";
import homeCard from "../components/home-card";
import welcomePopup from "../components/welcomePopup";
import Onboard from "../components/onboard.vue";
//import LineChart from "../js/linechart.js";

import BarChart from "../js/bar.js";
import DoughnutChart from "../js/doughnut.js";

export default {
  components: {
    // LineChart,
    BarChart,
    DoughnutChart,
    // PieChart,
    homeCard,
    welcomePopup,
    Onboard,
  },
  data: () => {
    return {
      currentTime: "",
      visibleUsers: [],
      preloader: false,
      callDialog: false,
      memberDialog: false,
      contactDialog: false,
      contactCardActive: false,
      callCardActive: false,
      memberCardActive: false,
      e6: 1,
      allUsers: [],
      callsData: [],
      showWizard: true,
      loadChart: true,
      search: "",
      datacollection: {},
      datacollectionDoughnut: {},
      callDetails: [],
      lastActivity: [],
      totalCallsToday: 0,
      labelShown: true,

      headers: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Role", align: "left", sortable: true, value: "role" },
        {
          text: "Last Call",
          align: "left",
          sortable: true,
          value: "last.formatedTime",
        },
        {
          text: "Last Call Name",
          align: "left",
          sortable: true,
          value: "last.name",
        },
        {
          text: "Last Call Status",
          align: "left",
          sortable: true,
          value: "last.dispo",
        },
        {
          text: "Call Counts",
          align: "left",
          sortable: true,
          value: "last.callCounts",
        },
        {
          text: "Call Summary",
          align: "left",
          sortable: true,
          value: "last.totalDuration",
        },
        {
          text: "Last Seen",
          align: "left",
          sortable: true,
          value: "last.createdOn",
        },
      ],
      // pagination structure
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 4,
      recordsPerPage: [4, 8, 16, 32, 64],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "dispoList", "companyDetails"]),
    selectedTeam() {
      return this.$store.getters.selectedTeam;
    },
    noAgent() {
      return require("../assets/noAgent.png");
    },
    teams() {
      return this.$store.getters.teams;
    },
    loading() {
      return this.$store.state.loading;
    },
    noData() {
      return require("../assets/no-page-found.png");
    },
    stepImg1() {
      return require("../assets/step1.png");
    },
    stepImg2() {
      return require("../assets/step2.png");
    },
    stepImg3() {
      return require("../assets/step3.png");
    },
    stepImg4() {
      return require("../assets/step4.png");
    },
    noCall() {
      return require("../assets/nocall.png");
    },
    paginate() {
      return this.$store.getters.paginate;
    },
  },
  watch: {
    selectedTeam() {
      this.getLatestActivity();
      this.fillData();
    },
  },
  beforeDestroy() {
    clearInterval(this.timeInterval); // Clear the interval when the component is destroyed
  },
  async created() {
    await this.refreshData();
  },
  mounted() {
    this.updateTime(); // Set the initial time
    this.timeInterval = setInterval(this.updateTime, 1000);
    // welcome screen
    if (typeof this.companyDetails == "undefined") {
      return;
    }

    if (this.companyDetails != null && this.companyDetails.ftsDone === false) {
      // open dialog for welcome pop up
      this.$refs.welcomePopupOpen.open();
    }
  },
  methods: {
    updateTime() {
      this.currentTime = this.$moment().format("Do MMMM YYYY, h:mm:ss A");
    },
    clearSearch() {
      this.search = "";
      this.getLatestActivity();
    },
    async refresh() {
      await this.refreshData();
    },
    async refreshData() {
      // Paginate

      var routeName = this.$router.currentRoute.name
        .toString()
        .trim()
        .replace(/ /g, "")
        .toLowerCase();
      if (this.paginate[routeName]) {
        this.page = this.paginate[routeName].page;
        this.pageCount = this.paginate[routeName].pageCount;
        this.totalRecords = this.paginate[routeName].totalRecords;
        this.records = this.paginate[routeName].records;

        if (this.totalRecords < this.records) {
          this.records = this.totalRecords;
        }
      }

      console.log("this.page", this.page);
      console.log("this.pageCount", this.pageCount);
      console.log("this.totalRecords", this.totalRecords);
      console.log("this.records", this.records);

      await this.getLatestActivity();

      this.fillData();
      // this.memberInCompany();
      // this.contactInCompany();
      // this.callInCompany();
      this.getCompanyParams();
    },
    addContactImgDialog() {
      this.contactDialog = true;
    },
    addMemberImgDialog() {
      this.memberDialog = true;
    },
    addCallImgDialog() {
      this.callDialog = true;
    },
    async getCompanyParams() {
      this.$http
        .post(this.ENDPOINT + "/companies/check-company-params", {
          companyId: this.$store.getters.user.companyId,
        })
        .then((response) => {
          this.callCardActive = false;
          this.contactCardActive = false;
          this.memberCardActive = false;
          if (response.body.accounts < 2) {
            this.memberCardActive = true;
          }
          if (response.body.contacts < 1) {
            this.contactCardActive = true;
          }
          if (response.body.logs < 1) {
            this.callCardActive = true;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    async doneWizard() {
      this.loading = true;
      await this.$http.patch(
        this.ENDPOINT + "/companies/" + this.companyDetails.id,
        {
          setupDone: true,
        }
      );

      this.$store.dispatch("getCompanyDetails", {
        companyId: this.companyDetails.id,
      });

      this.loading = false;
    },

    fillData() {
      var self = this;
      // Teams sections
      var teamIds = this.getTeamIds();

      self.callDetails = [];
      self.loadChart = false;

      var startDate = moment()
        .subtract(6, "d")
        .format("YYYY-MM-DD");
      var endDate = moment().format("YYYY-MM-DD");

      self.$http
        .post(self.ENDPOINT + "/logs/v2/countbyday", {
          range: [startDate, endDate],
          teamsId: teamIds,
        })
        .then((res) => {
          self.totalCallsToday = 0;
          try {
            if (res.body.length > 0) {
              var days = {};
              var pie = {};
              _.each(res.body, (r) => {
                let k = moment(r._id.day).format();

                if (typeof days[k] == "undefined") days[k] = 0;
                days[k] = days[k] + r.calls;

                if (typeof pie[k] == "undefined") pie[k] = [];
                pie[k].push(r.status);
              });

              let data = _.map(_.keys(days), (key) => {
                return { x: moment(key).toDate(), y: days[key] };
              });

              data = _.orderBy(data, (d) => {
                return d.x;
              });

              // populate data for line chart
              self.datacollection = {
                datasets: [
                  {
                    label: "Calls Per Day",
                    backgroundColor: "#4cd964",
                    data: data,
                  },
                ],
              };

              let current = moment().format("YYYY-MM-DD");
              var today = [];
              for (var x in pie) {
                if (
                  moment(current).isSame(moment(x).format("YYYY-MM-DD"), "day")
                ) {
                  today = pie[x];
                  break;
                }
              }

              pie = {};
              _.each(today, (t) => {
                _.mapKeys(t, (value, key) => {
                  if (typeof pie[key] == "undefined") pie[key] = 0;
                  pie[key] = pie[key] + value;
                });
              });

              var colors = [];

              this.labelShown = false; // mention at top
              let result = Object.keys(pie).map((key) => ({
                _id: key,
                count: Number(pie[key]),
              }));
              pie = Object.assign(result);

              pie = _.orderBy(pie, ["count"], ["desc"]);
              if (pie.length > 10) {
                var pieNew = pie.slice(0, 10);
                var pieOther = {
                  _id: "Others",
                  count: 0,
                };
                for (let i = 10; i < pie.length; i++) {
                  pieOther.count += pie[i].count;
                }
                pie = pieNew.concat(pieOther);
              }

              pie.forEach((val) => {
                val = _.find(self.dispoList, (d) => {
                  return d.title == val._id;
                });
                if (val == undefined) {
                  colors.push("#d9d9d9");
                } else {
                  colors.push(val.hex);
                }
              });

              self.datacollectionDoughnut = {
                datasets: [
                  {
                    backgroundColor: colors,
                    data: _.map(pie, "count"), // [..._.map(pie)],
                    borderWidth: 0,
                  },
                ],
                labels: _.map(pie, "_id"), //Object.keys(pie),
              };

              // console.log(
              //   "self.dataCollection pie",
              //   JSON.stringify(self.datacollectionDoughnut)
              // );

              self.$nextTick(() => {
                self.labelShown = true;
                self.loadChart = true;
                var current = moment();
                var today = _.find(data, (d) => {
                  return current.isSame(moment(d.x), "day");
                });
                self.totalCallsToday =
                  typeof today != "undefined" ? today.y : 0;
              });
            }
          } catch (e) {
            console.log("Error", e);
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },

    /** PAGINATION */
    //next and prev buttons
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      try {
        this.pageCount = Math.ceil(this.totalRecords / this.records);
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        await this.getLatestActivity(
          this.records,
          this.records * this.page - this.records
        );

        this.paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = {
          page: this.page,
          pageCount: this.pageCount,
          records: this.records,
          totalRecords: this.totalRecords,
        };
        this.$store.commit("setPagination", this.paginate);
        return;
      } catch (e) {
        console.log(e);
        this.preloader = false;
      }
    },
    // fetch the total number of data avail
    async fetchCount() {
      this.preloader = true;

      // Teams sections
      var teamIds = this.getTeamIds();

      // var where = {
      //   companyId: this.$store.getters.user.companyId,
      //   teamsId: {
      //     inq: teamIds,
      //   },
      // };
      // if (this.search != "" && this.search != null) {
      //   // this.search = new RegExp(this.search.toLowerCase().trim(), "i");
      //   where.or = [
      //     {
      //       username: {
      //         like: this.search,
      //         options: "i",
      //       },
      //     },
      //     {
      //       name: {
      //         like: this.search,
      //         options: "i",
      //       },
      //     },
      //     {
      //       email: {
      //         like: this.search,
      //         options: "i",
      //       },
      //     },
      //   ];
      // }

      var response = await this.$http.post(
        `${this.$store.state.ENDPOINT}/accounts/fetchCount`,
        {
          teams: teamIds,
          search: this.search,
        }
      );

      this.totalRecords = response.body;
      this.pageCount = Math.ceil(this.totalRecords / this.records);
      this.preloader = false;

      // this.$http
      //   .get(
      //     `${
      //       this.$store.state.ENDPOINT
      //     }/accounts/count?where=${encodeURIComponent(JSON.stringify(where))}`
      //   )
      //   .then((res) => {
      //     this.totalRecords = res.body.count;
      //     this.pageCount = Math.ceil(this.totalRecords / this.records);
      //     this.preloader = false;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.preloader = false;
      //   });
    },
    /** recieve teams array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    // get the users and also used for pagination
    getLatestActivity(limit, skip, search) {
      var self = this;
      this.preloader = true;

      if (limit == undefined || skip == undefined) {
        limit = this.records;
        skip = 0;
      }

      if (search == undefined) {
        search = "";
      }

      // Teams sections
      var teamIds = this.getTeamIds();

      self.$http
        .post(`${self.ENDPOINT}/accounts/lastActivity`, {
          teamsId: teamIds,
          limit: limit,
          skip: skip,
          search: search,
        })
        .then(async (response) => {
          self.allUsers = JSON.parse(JSON.stringify(response.body));

          for (var x in self.allUsers) {
            if (self.allUsers[x].last != undefined) {
              if (
                moment().diff(
                  moment(self.allUsers[x].last.calledOn),
                  "Minutes"
                ) < 5
              ) {
                self.allUsers[x].activeAgent = true;
              } else {
                self.allUsers[x].activeAgent = false;
              }
            } else {
              self.allUsers[x].activeAgent = false;
            }
          }

          // self.visibleUsers = self.allUsers;

          self.visibleUsers = self.allUsers.sort((a, b) => {
            return b.activeAgent - a.activeAgent;
          });

          var res = [];
          res = _.without(
            _.map(self.allUsers, (r) => {
              if (r.last != undefined) {
                r.last.formatedTime = moment(r.last.calledOn).format(
                  "YYYY-MM-DD hh:mm A"
                );
                r.last.calledOn = moment(r.last.calledOn).format(
                  "YYYY-MM-DD hh:mm A"
                );

                r.last.totalDuration = r.last.totalDuration / 1000;
                r.last.totalDuration = `${Number(
                  r.last.totalDuration / 60
                ).toFixed(1)} mins`;
              }

              return r;
            }),
            undefined
          );

          self.allUsers = res;

          self.preloader = false;
          self.fetchCount();
        })
        .catch((e) => {
          //error
          self.preloader = false;
          // self.allUsers = [];
          // self.visibleUsers = [];
          self.$swal({
            type: "error",
            text: "Error while getting all the member details",
          });
        });
    },
    getImage(src) {
      return `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
    },
  },
};
</script>

<style scoped>
.customPadding {
  padding-top: 0.5%;
}

.customColorForAvatarText {
  color: white !important;
  font-size: 24px !important;
}

.active-dot {
  height: 14px;
  width: 14px;
  background-color: #44ff00;
  border-radius: 50%;
  display: inherit;
  position: absolute;
  /* margin-top: 25px;
  margin-left: 20px; */
  margin-top: 44px;
  margin-left: 40px;
}

.dotted-gradient {
  background-image: linear-gradient(
    to right,
    #313131 40%,
    rgba(255, 255, 255, 0) 40%
  );
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  text-transform: inherit;
  padding-bottom: 2px;
  opacity: 0.7;
}
.live2 {
  /* position: absolute; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgb(95, 255, 47);
  margin: 12px 10px;
  animation-name: pulse;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
