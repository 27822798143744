<template>
  <v-card flat class="transparent fill-height">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="6">
        <v-card
          color="secondarygrad"
          class="rounded align-center pa-6 pt-4 pb-6"
          style="min-height: 122px;cursor: pointer;"
          @click="$router.push({ path: '/reports' })"
        >
          <h3 style="color:#30475A;" class="blue-black">
            Calls
          </h3>
          <div class="pt-2" style="color: grey;font-size: 30px;">
            {{ totalCalls }}
          </div>
          <div
            style="display: flex; justify-content: flex-end; margin-top: 6px; color: grey;font-size: 16px;"
          >
            Today
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card
          color="secondarygrad"
          class="rounded align-center pa-6 pt-4"
          style="min-height: 122px;cursor: pointer;"
          @click="$router.push({ path: '/users' })"
        >
          <h3 style="color:#30475A;" class="blue-black">
            Agents
          </h3>
          <div class="pt-2" style="color: grey;font-size: 30px;">
            {{ activeAgents }} / {{ totalAgents }}
          </div>
          <div
            style="display: flex; justify-content: flex-end; margin-top: 6px; color: grey;font-size: 16px;"
          >
            Active
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" class="pt-6">
        <v-card
          color="secondarygrad"
          class="rounded align-center pa-6 pt-4"
          style="min-height: 122px;cursor: pointer;"
          @click="$router.push({ path: '/productivity-attendance' })"
        >
          <h3 style="color:#30475A;" class="blue-black">
            Attendance
          </h3>
          <div class="pt-2" style="color: grey;font-size: 30px;">
            {{ totalAttendanceCount }} / {{ totalAgents }}
          </div>
          <div
            style="display: flex; justify-content: flex-end; margin-top: 6px; color: grey;font-size: 16px;"
          >
            Present
          </div>
        </v-card>
      </v-col>

      <!-- <v-col cols="6" md="12" class="pa-2 ma-0">
        <v-card
          class="secondarygrad rounded fill-height py-4 customPaddingVCard"
        >
          <v-card-title class="ma-0 pa-2 secondary--text text--lighten-2">
            Calls
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  class="primarygrad"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: '/reports' })"
                >
                  <v-icon color="white"> mdi-phone</v-icon>
                </v-btn>
              </template>
              <span>Detail Report</span>
            </v-tooltip>
          </v-card-title>
          <h2 class="text-center pa-2" style="line-height:1em;">
            {{ totalCalls }}
          </h2>
          <h4 class="text-center font-weight-medium pt-2 secondary--text">
            Today
          </h4>
        </v-card>
      </v-col>
      <v-col cols="6" md="12" class="pa-2 ma-0 pt-4">
        <v-card
          class="secondarygrad rounded fill-height py-4 customPaddingVCard"
        >
          <v-card-title class="ma-0 px-2 pa-1 secondary--text text--lighten-2">
            Agents
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  class="primarygrad"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: '/users' })"
                >
                  <v-icon color="white">mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Members</span>
            </v-tooltip>
          </v-card-title>
          <h2 class="text-center pa-2" style=" line-height:1em;">
            {{ activeAgents }} / {{ totalAgents }}
          </h2>
          <h4 class="text-center font-weight-medium pt-2 secondary--text">
            Active / Total
          </h4>
        </v-card>
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    compiledData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalAgents: 0,
      activeAgents: 0,
      totalCalls: 0,
      totalCallsByCompany: 0,
      totalAttendanceCount: 0,
      cCount: {
        called: 0,
        uncalled: 0,
      },
    };
  },
  computed: {
    allUsers() {
      return this.$store.getters.allUsers;
    },
    selectedTeam() {
      return this.$store.getters.selectedTeam;
    },
    teams() {
      return this.$store.getters.teams;
    },
  },
  watch: {
    selectedTeam() {
      this.getActiveStatusByTeam();
    },
  },
  async created() {
    await this.getAttendanceByDate();
    await this.getActiveStatusByTeam();
  },
  methods: {
    getTeamIds() {
      let teams = [];
      teams.push(this.selectedTeam);
      if (this.selectedTeam == "allTeams") {
        teams = _.without(_.map(this.teams, "id"), "allTeams");
      }
      return teams;
    },
    async getAttendanceByDate() {
      let startDate = moment()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      let endDate = moment()
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      // var resp = await this.$http.get(
      //   `${this.$store.state.ENDPOINT}/attendances?filter[where][companyId]=${this.$store.state.companyDetails.id}&filter[where][and][0][attendanceDay][gte]=${startDate}&filter[where][and][1][attendanceDay][lte]=${endDate}`
      // );

      var resp = await this.$http.get(
        `${this.$store.state.ENDPOINT}/attendances?filter=${encodeURIComponent(
          JSON.stringify({
            where: {
              and: [
                { companyId: this.$store.state.companyDetails.id },
                { attendanceDay: { gte: startDate } },
                { attendanceDay: { lte: endDate } },
              ],
            },
          })
        )}`
      );

      let uniqueAccounts = new Set();

      resp.body.forEach((item) => {
        uniqueAccounts.add(item.accountId);
      });

      this.totalAttendanceCount = uniqueAccounts.size;

      // console.log("resp 123", resp);
    },
    getActiveStatusByTeam() {
      let teamIds = this.getTeamIds();
      this.$http
        .post(`${this.$store.state.ENDPOINT}/logs/team-active-status`, {
          teamsId: teamIds,
        })
        .then((respo) => {
          respo = respo.body;
          this.totalAgents = respo.totalUser;
          this.activeAgents = respo.activeUser;
          this.totalCalls = respo.totalCalls;
        })
        .catch((err) => {
          console.log("Error: On Getting Active Status On Card", err);
        });
    },
  },
};
</script>
